<template>
  <div class="container page_unit_detail">
    <van-nav-bar
      :title="unit_name"
      left-arrow
      class="nav_bar"
      @click-left="nav_back"
    />

    <div class="statictis_box">
      <div
        :class="get_result_detail_change_box_span_class(0)"
        @click="result_detail_change_click(0)"
      >
        {{questions.length}}<br />
        全部
      </div>
      <div
        :class="get_result_detail_change_box_span_class(1)"
        @click="result_detail_change_click(1)"
      >
        {{wrong_questions.length}}<br />
        答错
      </div>
      <div
        :class="get_result_detail_change_box_span_class(2)"
        @click="result_detail_change_click(2)"
      >
        {{fav_questions.length}}<br />
        收藏
      </div>
    </div>

    <!-- 答题详情题目列表 -->
    <div class="result_detail_list_box">
      <van-swipe
        class="my-swipe"
        :show-indicators="false"
        :initial-swipe="result_detail_list_box_idx"
        @change="on_result_detail_list_box_change"
      >
        <!-- 全部 -->
        <van-swipe-item>
          <div
            class="result_detail_list"
            :class="result_detail_list_class(0)"
            v-for="(item, idx) in questions"
            :key="idx"
          >
            <div class="question_content">
              <div class="question" v-html="item.question"></div>
              <div class="answer" v-html="item.answer_text"></div>
            </div>

            <img
              src="~@/assets/img/practice/fav_off.png"
              class="fav"
              v-if="item.is_favorited == 0"
              @click="fav2(item.id)"
            />
            <img
              src="~@/assets/img/practice/fav_on.png"
              class="fav"
              v-if="item.is_favorited == 1"
              @click="cancel_fav2(item.id)"
            />
          </div>
        </van-swipe-item>
        <!-- 答错的 -->
        <van-swipe-item>
          <div
            class="result_detail_list"
            :class="result_detail_list_class(1)"
            v-for="(item, idx) in wrong_questions"
            :key="idx"
          >
            <div class="question_content">
              <div class="question" v-html="item.question"></div>
              <div class="answer" v-html="item.answer_text"></div>
            </div>

            <img
              src="~@/assets/img/practice/fav_off.png"
              class="fav"
              v-if="item.is_favorited == 0"
              @click="fav2(item.id)"
            />
            <img
              src="~@/assets/img/practice/fav_on.png"
              class="fav"
              v-if="item.is_favorited == 1"
              @click="cancel_fav2(item.id)"
            />
          </div>
        </van-swipe-item>
        <!-- 收藏的 -->
        <van-swipe-item>
          <div
            class="result_detail_list"
            :class="result_detail_list_class(2)"
            v-for="(item, idx) in fav_questions"
            :key="idx"
          >
            <div class="question_content">
              <div class="question" v-html="item.question"></div>
              <div class="answer" v-html="item.answer_text"></div>
            </div>

            <img
              src="~@/assets/img/practice/fav_off.png"
              class="fav"
              v-if="item.is_favorited == 0"
              @click="fav2(item.id)"
            />
            <img
              src="~@/assets/img/practice/fav_on.png"
              class="fav"
              v-if="item.is_favorited == 1"
              @click="cancel_fav2(item.id)"
            />
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>
<script>
import { Toast, NavBar, Swipe, SwipeItem, Icon } from "vant";
import { get_unit_questions,favorite_question,favorite_question_cancel } from "@/api/practice/api";
export default {
  name: "Unit",
  data() {
    return {
      unit_name: "",
      questions: [],
      wrong_questions: [],
      fav_questions: [],
      is_show_result: 0,
      result_detail_list_box_idx: 0,
      result_detail_list_box_idx_ani: 0
    };
  },
  watch: {
    $router: {
      handler: "req_get_unit_questions", //调用方法
      immediate: true //进入立即执行一次
    }
  },
  methods: {
    nav_back() {
      this.$router.push({ path: "/unit?ts=" + Math.random() });
    },
    req_get_unit_questions() {
      let id = this.$store.getters.unit_id;
      if (isNaN(id) || id == "") {
        this.nav_back();
        return;
      }

      get_unit_questions({
        unit_id: id
      }).then(res => {
        if (res.code != 200) {
          Toast(res.info);
          return;
        }
        this.unit_name = res.data.unit_name;
        this.questions = res.data.questions;
        this.calc_questions();
      });
    },
    //计算出答错的题目和收藏的题目
    calc_questions() {
      let wrong_questions = [];
      let fav_questions = [];
      for (let i = 0; i < this.questions.length; i++) {
        if (this.questions[i]["status"] == 2) {
          wrong_questions.push(this.questions[i]);
        }

        if (this.questions[i]["is_favorited"] == 1) {
          fav_questions.push(this.questions[i]);
        }
      }

      this.wrong_questions = wrong_questions;
      this.fav_questions = fav_questions;
      console.log("wrong_questions", wrong_questions);
      console.log("fav_questions", fav_questions);
    },
    //切换显示答案状态
    change_is_show_result() {
      if (this.is_show_result == 0) {
        this.is_show_result = 1;
        return;
      }
      this.is_show_result = 0;
    },
    //获取当前答案显示状态文案
    get_is_show_result_text() {
      if (this.is_show_result == 0) {
        return "显示答案";
      }
      return "隐藏答案";
    },
    //答对答错滑动
    on_result_detail_list_box_change(idx) {
      this.result_detail_list_box_idx_ani = idx;
    },
    //获取答对答错Tab class
    get_result_detail_change_box_span_class(idx) {
      if (this.result_detail_list_box_idx_ani == idx) {
        return "active";
      }
      return "";
    },
    //单击答错答对tab按钮
    result_detail_change_click(idx) {
      this.result_detail_list_box_idx = idx;
      this.result_detail_list_box_idx_ani = idx;
    },
    //获取列表显示状态class
    result_detail_list_class(idx){
        if(this.result_detail_list_box_idx_ani!=idx){
            return "hide"
        }
        return ""
    },
    //收藏
    fav2(id) {
      favorite_question({
        question_id: id
      }).then(res => {
        if (res.code != 200) {
          Toast(res.info);
          return;
        }

        for (let i = 0; i < this.questions.length; i++) {
          if (this.questions[i]["id"] == id) {
            this.questions[i]["is_favorited"] = 1;
          }
        }

        
        this.calc_questions()
      });
    },
    //取消收藏
    cancel_fav2(id) {
      favorite_question_cancel({
        question_id: id
      }).then(res => {
        if (res.code != 200) {
          Toast(res.info);
          return;
        }
        
        for (let i = 0; i < this.questions.length; i++) {
          if (this.questions[i]["id"] == id) {
            this.questions[i]["is_favorited"] = 0;
          }
        }

        this.calc_questions()
      });
    },
  },
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem
  }
};
</script>
<style lang="stylus">
.page_unit_detail .nav_bar{
    background #fff
    border-bottom 0
}
.page_unit_detail .van-nav-bar__content{
    height: 54px;
}
.page_unit_detail .van-nav-bar__title{
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000;
}
.page_unit_detail .van-hairline--bottom::after{
    border none
}
.page_unit_detail .van-nav-bar .van-icon{
    color #000
    font-size 18px
}
.page_unit_detail .van-nav-bar__text{
    color #1989fa
    font-size 15px
}
.page_unit_detail .van-nav-bar__right span.van-nav-bar__text {
    font-size: 12px;
}
</style>

<style lang="stylus" scoped>
.container{
    width 100%
    height 100%
    background #fff
    position relative
}
.statictis_box{
    display: flex;
    background: #fff;
    position: relative;
    z-index: 1;
}
.statictis_box>div{
    text-align: center;
    flex: 1;
    font-size: 14px;
    padding: 10px 0;
    color:#ccc
    border-bottom: none
}
.statictis_box>div.active{
    color: #000;
    border-bottom: 4px solid #ffc107;
    font-weight: bold;
}

.result_detail_list_box{
  position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding-top: 132px;
    padding-bottom: 10px;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: scroll;
}
.result_detail_list_box .result_detail_list{
  margin-top: 10px;
  font-size: 12px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  width: 90%;
  margin: 0 auto;
  text-align: left;
  padding: 10px 60px 10px 12px;
  position relative
}
.result_detail_list_box .result_detail_list .qa_right_wrong_state{
  position: absolute;
  background: #afd9fb;
  color: #fff;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  line-height: 45px;
  text-align: center;
  left: 13px;
  top: 50%;
  margin-top: -15px;
}
.result_detail_list_box .result_detail_list .qa_right_wrong_state.qa_right_wrong_state_2{
  background #ffd4d4
}
.result_detail_list_box .result_detail_list .question_content{

}
.result_detail_list_box .result_detail_list .question_content .question{
  font-size 14px
  font-weight 600
}
.result_detail_list_box .result_detail_list .question_content .answer{
  margin-top 10px
  color #aaa
}
.result_detail_list_box .result_detail_list .fav{
  position: absolute;
  top: 50%;
  height: 50px;
  margin-top: -21px;
  right: 2px;
}
</style>
